import { ready, oneLineTag } from './custom_utils';
import { da_result_download_path } from "../routes";

var checkJobsTaskHandle;
var previousJob = null;

ready(function () {
	if (document.getElementById('ready_da_jobs')) {
		checkJobsTaskHandle = setInterval(check_jobs_status, 5000)
	}
})


async function check_jobs_status() {
	console.log("Checking statuses of unfinished DA jobs..")
	let response = await fetch('/api/check_da_jobs')
	let responseObject = await response.json();
	// console.log(responseObject)

	let unfinishedStatuses = ['pending', 'inprogress', 'download_error'];
	let unfinishedJobs = [];
	unfinishedStatuses.forEach(unfinishedStatus => {
		if (responseObject[unfinishedStatus] != undefined && responseObject[unfinishedStatus] != null && responseObject[unfinishedStatus].length > 0) {
			unfinishedJobs = unfinishedJobs.concat(responseObject[unfinishedStatus])
		}
	});
	
	if (unfinishedJobs.length == 0){
		clearInterval(checkJobsTaskHandle);
		clearFlashNotices();
	}

	let earliestJob = null;
	if (unfinishedJobs.length > 0) {
		unfinishedJobs.forEach(da_job => {
			if (earliestJob == null)
				earliestJob = da_job

			if (da_job.created_at > earliestJob.created_at)
				earliestJob = da_job
		});
	}

	let readyJobs = document.getElementById("ready_da_jobs")
	let readyJobList = document.getElementById("ready_da_jobs_list")

	// console.log(previousJob)

	if (previousJob != null && responseObject.success) {
		responseObject.success.forEach(job => {
			if (job.id != previousJob.id) {
				return;
			}

			readyJobs.removeAttribute('hidden');
			clearFlashNotices();
			let listItem = document.createElement('li');
			let newElem = oneLineTag('a', { class: "alert-link", href: da_result_download_path(job.id) });
			newElem.appendChild(document.createTextNode(`${job.filename}`));
			listItem.appendChild(newElem);
			readyJobList.appendChild(listItem);
			clearInterval(checkJobsTaskHandle);
		});
	}

	previousJob = earliestJob
}

function clearFlashNotices() {
	let flashElements = document.querySelectorAll('#flash_messages div.flash.notice');
	flashElements.forEach(flash => {
		flash.remove();
	});
}

function checkForChanges() {

}