/**
 * File generated by js-routes 2.2.5
 * Based on Rails 6.0.6.1 routes of Licensestore::Application
 */
// eslint-disable-next-line
const __jsr = (
// eslint-disable-next-line
() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) ||
                (true &&
                    result === false)
                ? ""
                : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /api/check_da_jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_check_da_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"check_da_jobs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/specifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_specifications_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"specifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bim_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bim_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bim_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bim_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /bim_files/destroy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bim_files_destroy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bim_files/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bim_files_edit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bim_files/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bim_files_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bim_files/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bim_files_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /design_automation_jobs/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const da_result_download_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"design_automation_jobs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /design_automation_jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const design_automation_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"design_automation_jobs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /downloads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /downloads/:download_id/download_versions/:id(.:format)
 * @param {any} download_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_download_version_path = __jsr.r({"download_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"download_id"],[2,[7,"/"],[2,[6,"download_versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /downloads/:download_id/download_versions(.:format)
 * @param {any} download_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_download_versions_path = __jsr.r({"download_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"download_id"],[2,[7,"/"],[2,[6,"download_versions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /downloads/:id/latest(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_latest_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"latest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_pdf_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /download_versions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"download_versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /download_versions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_version_update_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"download_versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /downloads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const downloads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /bim_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_bim_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /downloads/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_download_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /downloads/:download_id/download_versions/:id/edit(.:format)
 * @param {any} download_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_download_download_version_path = __jsr.r({"download_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"download_id"],[2,[7,"/"],[2,[6,"download_versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /email_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_email_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"email_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /families/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_family_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"families"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /forms/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_form_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"forms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /forms/:form_id/form_controls/:id/edit(.:format)
 * @param {any} form_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_form_form_control_path = __jsr.r({"form_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"forms"],[2,[7,"/"],[2,[3,"form_id"],[2,[7,"/"],[2,[6,"form_controls"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /licenses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_license_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /licenses/:license_id/seats/:id/edit(.:format)
 * @param {any} license_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_license_seat_path = __jsr.r({"license_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[3,"license_id"],[2,[7,"/"],[2,[6,"seats"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /license_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_license_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"license_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /models/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /model_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_model_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"model_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /pages/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /parameters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_parameter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"parameters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /parameter_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_parameter_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"parameter_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_password_reset_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /permissions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"permissions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /prices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_price_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"prices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /quotations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /models/:id/edit_remote_attachment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_remote_attachment_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit_remote_attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_session_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /statuses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tutorials/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tutorial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /email_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"email_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /email_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"email_templates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/export/csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const export_users_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"export"],[2,[7,"/"],[2,[6,"csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /families(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const families_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"families"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /families/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const family_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"families"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /forms/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const form_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"forms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /forms/:form_id/form_controls/:id(.:format)
 * @param {any} form_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const form_form_control_path = __jsr.r({"form_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"forms"],[2,[7,"/"],[2,[3,"form_id"],[2,[7,"/"],[2,[6,"form_controls"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /forms/:form_id/form_controls(.:format)
 * @param {any} form_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const form_form_controls_path = __jsr.r({"form_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"forms"],[2,[7,"/"],[2,[3,"form_id"],[2,[7,"/"],[2,[6,"form_controls"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /forms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"forms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /get_price(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_price_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"get_price"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /licenses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const license_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /licenses/:license_id/seats/:id(.:format)
 * @param {any} license_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const license_seat_path = __jsr.r({"license_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[3,"license_id"],[2,[7,"/"],[2,[6,"seats"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /licenses/:license_id/seats(.:format)
 * @param {any} license_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const license_seats_path = __jsr.r({"license_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[3,"license_id"],[2,[7,"/"],[2,[6,"seats"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /license_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const license_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"license_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /license_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const license_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"license_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /licenses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const licenses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /models/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /models/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const model_download_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /models/:id/generate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const model_generate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /model_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const model_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"model_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /catalogue/:type_name(.:format)
 * @param {any} type_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const model_type_index_path = __jsr.r({"type_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"catalogue"],[2,[7,"/"],[2,[3,"type_name"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /catalogue(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const model_type_index_without_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"catalogue"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /model_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const model_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"model_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /models(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const models_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"models"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /parameters/:id/move(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_parameter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"parameters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tutorials/:id/move(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const move_tutorial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"move"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /bim_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_bim_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bim_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /downloads/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_download_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /downloads/:download_id/download_versions/new(.:format)
 * @param {any} download_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_download_download_version_path = __jsr.r({"download_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"download_id"],[2,[7,"/"],[2,[6,"download_versions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /email_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_email_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"email_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /families/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_family_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"families"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /forms/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_form_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"forms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /forms/:form_id/form_controls/new(.:format)
 * @param {any} form_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_form_form_control_path = __jsr.r({"form_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"forms"],[2,[7,"/"],[2,[3,"form_id"],[2,[7,"/"],[2,[6,"form_controls"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /licenses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_license_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /licenses/:license_id/seats/new(.:format)
 * @param {any} license_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_license_seat_path = __jsr.r({"license_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"licenses"],[2,[7,"/"],[2,[3,"license_id"],[2,[7,"/"],[2,[6,"seats"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /license_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_license_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"license_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /models/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_model_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /model_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_model_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"model_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pages/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_page_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /parameters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_parameter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"parameters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /parameter_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_parameter_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"parameter_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password_resets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_password_reset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /permissions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_permission_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"permissions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /prices/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_price_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"prices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_quotation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_role_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /statuses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statuses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tutorials/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tutorial_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /page_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"page_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /page_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const page_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"page_settings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /pages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pages"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /parameters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parameter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"parameters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /parameter_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parameter_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"parameter_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /parameter_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parameter_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"parameter_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /parameter_values(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parameter_values_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"parameter_values"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /parameters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parameters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"parameters"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /password_resets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_reset_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /password_resets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_resets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"password_resets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /permissions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const permission_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"permissions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /permissions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const permissions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"permissions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /permissions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const permissions_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"permissions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /quotations/:id/order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const place_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /prices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const price_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"prices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /prices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"prices"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /quotations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /quotations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const quotations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"quotations"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /models/:id/remove_remote_attachment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_remote_attachment_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_remote_attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /sessions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const session_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sessions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /srassword_resets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const srassword_resets_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"srassword_resets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const statuses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"statuses"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tutorials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tutorial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tutorials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tutorials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tutorials/create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tutorials_create_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tutorials/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tutorials_remove_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tutorials/show(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tutorials_show_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[6,"show"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tutorials/update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tutorials_update_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tutorials"],[2,[7,"/"],[2,[6,"update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /models/:id/update_remote_attachment(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_remote_attachment_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_remote_attachment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /models/:id/upload_remote_attachments(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_remote_attachments_model_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"models"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_remote_attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /uploader/image(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const uploader_image_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uploader"],[2,[7,"/"],[2,[6,"image"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:uuid/set_locale/:locale(.:format)
 * @param {any} uuid
 * @param {any} locale
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_set_locale_path = __jsr.r({"uuid":{"r":true},"locale":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"uuid"],[2,[7,"/"],[2,[6,"set_locale"],[2,[7,"/"],[2,[3,"locale"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /validate_url(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_url_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"validate_url"],[1,[2,[8,"."],[3,"format"]]]]]);

